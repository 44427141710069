import React from 'react';
import { Layout } from '../components/Layout';

const Home = () => {
  return (
    <Layout>
      <h1>About Me</h1>
      <h3>Overview</h3>
      <p>
        My name is Jim, and I am a software engineer in central Iowa
        specializing in fullstack development.
      </p>
      <p>
        In my spare time, I run a small sheep farm with my family. In addition
        to raising lambs, we have an orchard of vintage and antique apples.
      </p>
      <p>
        My professional experience and passion lie in building exceptional
        products and collaborating with people. I understand this isn't the most
        exciting <b>About Me</b> section you've ever read, but I just needed to
        put some boilerplate here in case anyone clicks off the main blog page.
      </p>
      <p>
        I've recently decided to start writing about interesting situations I
        encounter while working on either personal or professional projects. The
        goal is to &mdash; at a minimum &mdash; create a blogpost every time a
        senior engineer writes more than a paragraph answer to a question in
        Slack. I figure it'll at least keep me honest through the remainder of
        this quarantine!
      </p>
      <p>
        Feel free to reach out; I love meeting people, and hope you enjoy my
        site.
      </p>
      <h3>Quick Hits</h3>
      <h4>I believe:</h4>
      <ul>
        <li>Agile is great but scrum is a scam</li>
        <li>Standup should be shorter</li>
        <li>Story points are futile</li>
        <li>Good code is only DRY sometimes</li>
        <li>Succinct syntax is generally overrated</li>
        <li>Consistency matters more than people think</li>
        <li>Unit tests are good &mdash; integration tests are better</li>
      </ul>
    </Layout>
  );
};

export default Home;
